<template>
  <div>
    <div class="text-right">
      <button
        @click="handleDownload()"
        class="px-4 py-2 mb-4 bg-gray-50 border rounded-md text-balck flex"
      >
        <img src="../../../assets/excel.png" alt="" />
        <span class="mx-3">Download</span>
      </button>
    </div>

    <div class="flex text-lg mb-4">
      <h1 class="pr-4 border-r uppercase font-bold">
        {{ $route.params.reportOn.replace(/_/g, " ") }}
      </h1>
      <h1 class="px-4 border-r">
        From:
        <span class="font-bold">{{
          $route.params.from | moment("ddd, MMM Do YYYY")
        }}</span>
      </h1>
      <h1 class="px-4">
        To:
        <span class="font-bold">{{
          $route.params.to | moment("ddd, MMM Do YYYY")
        }}</span>
      </h1>
    </div>
    <table class="min-w-full divide-y divide-gray-200">
      <thead class="bg-gray-50">
        <tr>
          <th
            scope="col"
            class="
              px-6
              py-3
              text-left text-xs
              font-medium
              text-gray-500
              uppercase
              tracking-wider
            "
          >
            Date
          </th>

          <th
            scope="col"
            class="
              px-6
              py-3
              text-center text-xs
              font-medium
              text-gray-500
              uppercase
              tracking-wider
            "
          >
            Staff / Branch
          </th>

          <th
            scope="col"
            class="
              px-6
              py-3
              text-center text-xs
              font-medium
              text-gray-500
              uppercase
              tracking-wider
            "
          >
            Transaction No.
          </th>
          <th
            scope="col"
            class="
              px-6
              py-3
              text-left text-xs
              font-medium
              text-gray-500
              uppercase
              tracking-wider
            "
          >
            Amount
          </th>

          <th
            scope="col"
            class="
              px-6
              py-3
              text-left text-xs
              font-medium
              text-gray-500
              uppercase
              tracking-wider
            "
          >
            Commission
          </th>
          <th
            scope="col"
            class="
              px-6
              py-3
              text-xs
              font-medium
              text-right text-gray-500
              uppercase
              tracking-wider
            "
          >
            Total
          </th>
        </tr>
      </thead>

      <tbody class="bg-white divide-y divide-gray-200">
        <tr v-for="(transaction, i) in data" :key="i">
          <td class="px-6 py-4 whitespace-nowrap">
            <div class="flex items-center">
              <div class="ml-4">
                <div class="text-sm font-medium text-gray-900">
                  {{ i }}
                </div>
              </div>
            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-center">
            <div class="text-sm text-gray-900">
              {{ transaction.branch }} / {{ transaction.staff }}
            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-center">
            <div class="text-sm text-gray-900">
              {{ transaction.transactions }}
            </div>
          </td>

          <td class="px-6 py-4 whitespace-nowrap">
            <div class="text-sm text-gray-900">
              GMD
              <b>{{ formatPrice(transaction.amount) }}</b>
            </div>
          </td>

          <td class="px-6 py-4 whitespace-nowrap">
            <div class="text-sm text-gray-900">
              GMD <b>{{ formatPrice(transaction.commission) }}</b>
            </div>
          </td>

          <td class="px-6 py-4 text-center whitespace-nowrap text-right">
            <div class="text-sm font-bold text-gray-900">
              GMD
              <b>{{
                formatPrice(transaction.amount + transaction.commission)
              }}</b>
            </div>
          </td>
        </tr>

        <tr class="bg-gray-50">
          <td class="px-6 py-4 whitespace-nowrap">
            <div class="flex items-center">
              <div class="ml-4">
                <div class="text-sm font-medium text-gray-900">
                  <b>TOTAL</b>
                </div>
              </div>
            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-center">
            <div class="text-sm text-gray-900">
              <!-- <b>{{ totalTransactions }}</b> -->
            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap text-center">
            <div class="text-sm text-gray-900">
              <b>{{ totalTransactions }}</b>
            </div>
          </td>
          <td class="px-6 py-4 whitespace-nowrap">
            <div class="text-sm text-gray-900">
              GMD <b>{{ formatPrice(totalAmount) }}</b>
            </div>
          </td>

          <td class="px-6 py-4 whitespace-nowrap">
            <div class="text-sm text-gray-900">
              GMD <b>{{ formatPrice(totalCommission) }}</b>
            </div>
          </td>

          <td class="px-6 py-4 text-center whitespace-nowrap text-right">
            <div class="text-sm font-bold text-gray-900">
              GMD
              <b>{{ getAllTotal(total, totalCommission) }}</b>
            </div>
          </td>
        </tr>

        <!-- More people... -->
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: ["data"],

  data: () => ({
    downloadLoading: false,

    filename: "",
    autoWidth: true,
    bookType: "xlsx",
  }),

  computed: {
    totalTransactions: function () {
      let total = [];

      Object.entries(this.data).forEach(([key, val]) => {
        console.log(key);
        total.push(val.transactions); // the value of the current key.
      });

      return total.reduce(function (total, num) {
        return total + num;
      }, 0);
    },

    totalAmount: function () {
      let total = [];

      Object.entries(this.data).forEach(([key, val]) => {
        console.log(key);
        total.push(val.amount); // the value of the current key.
      });

      return total.reduce(function (total, num) {
        return total + num;
      }, 0);
    },

    totalCommission: function () {
      let total = [];

      Object.entries(this.data).forEach(([key, val]) => {
        console.log(key);
        total.push(val.commission); // the value of the current key.
      });

      return total.reduce(function (total, num) {
        return total + num;
      }, 0);
    },

    total: function () {
      let total = [];

      Object.entries(this.data).forEach(([key, val]) => {
        console.log(key);
        total.push(val.amount + val.commission); // the value of the current key.
      });

      return total.reduce(function (total, num) {
        return total + num;
      }, 0);
    },
  },

  methods: {
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(",", ".");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    getAmount(amount, commission) {
      const amt = Number(amount - commission);
      return this.formatPrice(amt);
    },

    getTotal(amount, commission) {
      const amt = Number(amount - commission) + Number(commission);
      return this.formatPrice(amt);
    },

    getAllAmount(totalAmt, totalComm) {
      const data = totalAmt - totalComm;
      return this.formatPrice(data);
    },
    getAllTotal(amnt, comm) {
      const data = amnt - comm;
      console.log(amnt, comm);
      return this.formatPrice(data);
    },

    getFileName() {
      return (
        this.$route.params.reportOn +
        "-" +
        this.$route.params.from +
        "-" +
        this.$route.params.to
      );
    },

    handleDownload() {
      this.downloadLoading = true;
      import("@/vendor/Export2Excel").then((excel) => {
        const tHeader = [
          "Date",
          "Staff/Branch",
          "Transactions",
          "Amount",
          "Commission",
          "Total",
        ];
        const filterVal = [
          "date",
          "staff/branch",
          "transactions",
          "amount",
          "commission",
          "total",
        ];
        const list = this.data;
        console.log("LIST", list);
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: this.getFileName(),
          autoWidth: this.autoWidth,
          bookType: this.bookType,
        });
        this.downloadLoading = false;
      });
    },

    formatJson(filterVal, jsonData) {
      return Object.keys(jsonData).map((v) => {
        console.log();
        return filterVal.map((j) => {
          console.log("J", j);
          console.log();

          if (j === "date") {
            return v;
          } else if (j === "total") {
            return (
              Number(jsonData[v]["amount"]) + Number(jsonData[v]["commission"])
            );
          } else if (j === "staff/branch") {
            return jsonData[v]["staff"] + " / " + jsonData[v]["branch"];
          } else {
            return jsonData[v][j];
          }
        });
      });
    },
  },
};
</script>
